export const Loader = () => import('../../components/base/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/base/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Popup = () => import('../../components/base/Popup.vue' /* webpackChunkName: "components/popup" */).then(c => wrapFunctional(c.default || c))
export const User = () => import('../../components/user.vue' /* webpackChunkName: "components/user" */).then(c => wrapFunctional(c.default || c))
export const FormulateButton = () => import('../../components/formulate/Button.vue' /* webpackChunkName: "components/formulate-button" */).then(c => wrapFunctional(c.default || c))
export const FormulateCalendar = () => import('../../components/formulate/Calendar.vue' /* webpackChunkName: "components/formulate-calendar" */).then(c => wrapFunctional(c.default || c))
export const FormulateDate = () => import('../../components/formulate/Date.vue' /* webpackChunkName: "components/formulate-date" */).then(c => wrapFunctional(c.default || c))
export const FormulatePassword = () => import('../../components/formulate/Password.vue' /* webpackChunkName: "components/formulate-password" */).then(c => wrapFunctional(c.default || c))
export const FormulateToggle = () => import('../../components/formulate/Toggle.vue' /* webpackChunkName: "components/formulate-toggle" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowLeft = () => import('../../components/icons/ArrowLeft.vue' /* webpackChunkName: "components/icons-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconsCircles = () => import('../../components/icons/Circles.vue' /* webpackChunkName: "components/icons-circles" */).then(c => wrapFunctional(c.default || c))
export const IconsCirclesBright = () => import('../../components/icons/CirclesBright.vue' /* webpackChunkName: "components/icons-circles-bright" */).then(c => wrapFunctional(c.default || c))
export const IconsCross = () => import('../../components/icons/Cross.vue' /* webpackChunkName: "components/icons-cross" */).then(c => wrapFunctional(c.default || c))
export const IconsExit = () => import('../../components/icons/Exit.vue' /* webpackChunkName: "components/icons-exit" */).then(c => wrapFunctional(c.default || c))
export const IconsEye = () => import('../../components/icons/Eye.vue' /* webpackChunkName: "components/icons-eye" */).then(c => wrapFunctional(c.default || c))
export const IconsEyeSlash = () => import('../../components/icons/EyeSlash.vue' /* webpackChunkName: "components/icons-eye-slash" */).then(c => wrapFunctional(c.default || c))
export const IconsFeedback = () => import('../../components/icons/Feedback.vue' /* webpackChunkName: "components/icons-feedback" */).then(c => wrapFunctional(c.default || c))
export const IconsFire = () => import('../../components/icons/Fire.vue' /* webpackChunkName: "components/icons-fire" */).then(c => wrapFunctional(c.default || c))
export const IconsHome = () => import('../../components/icons/Home.vue' /* webpackChunkName: "components/icons-home" */).then(c => wrapFunctional(c.default || c))
export const IconsInfo = () => import('../../components/icons/Info.vue' /* webpackChunkName: "components/icons-info" */).then(c => wrapFunctional(c.default || c))
export const IconsLogo = () => import('../../components/icons/Logo.vue' /* webpackChunkName: "components/icons-logo" */).then(c => wrapFunctional(c.default || c))
export const IconsLogoSmall = () => import('../../components/icons/LogoSmall.vue' /* webpackChunkName: "components/icons-logo-small" */).then(c => wrapFunctional(c.default || c))
export const IconsMedals1 = () => import('../../components/icons/Medals1.vue' /* webpackChunkName: "components/icons-medals1" */).then(c => wrapFunctional(c.default || c))
export const IconsMedals2 = () => import('../../components/icons/Medals2.vue' /* webpackChunkName: "components/icons-medals2" */).then(c => wrapFunctional(c.default || c))
export const IconsMedals3 = () => import('../../components/icons/Medals3.vue' /* webpackChunkName: "components/icons-medals3" */).then(c => wrapFunctional(c.default || c))
export const IconsPen = () => import('../../components/icons/Pen.vue' /* webpackChunkName: "components/icons-pen" */).then(c => wrapFunctional(c.default || c))
export const IconsPerson = () => import('../../components/icons/Person.vue' /* webpackChunkName: "components/icons-person" */).then(c => wrapFunctional(c.default || c))
export const IconsPlantsCollection = () => import('../../components/icons/PlantsCollection.vue' /* webpackChunkName: "components/icons-plants-collection" */).then(c => wrapFunctional(c.default || c))
export const IconsPlantsLeft = () => import('../../components/icons/PlantsLeft.vue' /* webpackChunkName: "components/icons-plants-left" */).then(c => wrapFunctional(c.default || c))
export const IconsPlantsRight = () => import('../../components/icons/PlantsRight.vue' /* webpackChunkName: "components/icons-plants-right" */).then(c => wrapFunctional(c.default || c))
export const IconsPlantsSide = () => import('../../components/icons/PlantsSide.vue' /* webpackChunkName: "components/icons-plants-side" */).then(c => wrapFunctional(c.default || c))
export const IconsSparkles = () => import('../../components/icons/Sparkles.vue' /* webpackChunkName: "components/icons-sparkles" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavigation = () => import('../../components/layout/Navigation.vue' /* webpackChunkName: "components/layout-navigation" */).then(c => wrapFunctional(c.default || c))
export const ModalsEnableTwoFactorModal = () => import('../../components/modals/EnableTwoFactorModal.vue' /* webpackChunkName: "components/modals-enable-two-factor-modal" */).then(c => wrapFunctional(c.default || c))
export const UiBottomSheet = () => import('../../components/ui/BottomSheet.vue' /* webpackChunkName: "components/ui-bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const UiGroupSwitch = () => import('../../components/ui/GroupSwitch.vue' /* webpackChunkName: "components/ui-group-switch" */).then(c => wrapFunctional(c.default || c))
export const UiHeading = () => import('../../components/ui/Heading.vue' /* webpackChunkName: "components/ui-heading" */).then(c => wrapFunctional(c.default || c))
export const UiHero = () => import('../../components/ui/Hero.vue' /* webpackChunkName: "components/ui-hero" */).then(c => wrapFunctional(c.default || c))
export const UiInfo = () => import('../../components/ui/Info.vue' /* webpackChunkName: "components/ui-info" */).then(c => wrapFunctional(c.default || c))
export const UiScoreboard = () => import('../../components/ui/Scoreboard.vue' /* webpackChunkName: "components/ui-scoreboard" */).then(c => wrapFunctional(c.default || c))
export const UiBadgesBadge = () => import('../../components/ui/Badges/Badge.vue' /* webpackChunkName: "components/ui-badges-badge" */).then(c => wrapFunctional(c.default || c))
export const UiBadgesRefresh = () => import('../../components/ui/Badges/Refresh.vue' /* webpackChunkName: "components/ui-badges-refresh" */).then(c => wrapFunctional(c.default || c))
export const UiBadges = () => import('../../components/ui/Badges/index.vue' /* webpackChunkName: "components/ui-badges" */).then(c => wrapFunctional(c.default || c))
export const UiCompetitionsCompetition = () => import('../../components/ui/Competitions/Competition.vue' /* webpackChunkName: "components/ui-competitions-competition" */).then(c => wrapFunctional(c.default || c))
export const UiCompetitions = () => import('../../components/ui/Competitions/index.vue' /* webpackChunkName: "components/ui-competitions" */).then(c => wrapFunctional(c.default || c))
export const UiFeedback = () => import('../../components/ui/Feedback/index.vue' /* webpackChunkName: "components/ui-feedback" */).then(c => wrapFunctional(c.default || c))
export const UiPopupsBadge = () => import('../../components/ui/Popups/Badge.vue' /* webpackChunkName: "components/ui-popups-badge" */).then(c => wrapFunctional(c.default || c))
export const UiPopupsExit = () => import('../../components/ui/Popups/Exit.vue' /* webpackChunkName: "components/ui-popups-exit" */).then(c => wrapFunctional(c.default || c))
export const UiQuizAnswer = () => import('../../components/ui/Quiz/Answer.vue' /* webpackChunkName: "components/ui-quiz-answer" */).then(c => wrapFunctional(c.default || c))
export const UiQuizProgress = () => import('../../components/ui/Quiz/Progress.vue' /* webpackChunkName: "components/ui-quiz-progress" */).then(c => wrapFunctional(c.default || c))
export const UiQuizResponseModal = () => import('../../components/ui/Quiz/ResponseModal.vue' /* webpackChunkName: "components/ui-quiz-response-modal" */).then(c => wrapFunctional(c.default || c))
export const UiQuiz = () => import('../../components/ui/Quiz/index.vue' /* webpackChunkName: "components/ui-quiz" */).then(c => wrapFunctional(c.default || c))
export const UiRankingBadge = () => import('../../components/ui/Ranking/Badge.vue' /* webpackChunkName: "components/ui-ranking-badge" */).then(c => wrapFunctional(c.default || c))
export const UiRankingComposition = () => import('../../components/ui/Ranking/Composition.vue' /* webpackChunkName: "components/ui-ranking-composition" */).then(c => wrapFunctional(c.default || c))
export const UiRanking = () => import('../../components/ui/Ranking/index.vue' /* webpackChunkName: "components/ui-ranking" */).then(c => wrapFunctional(c.default || c))
export const UiTrainingsTraining = () => import('../../components/ui/Trainings/Training.vue' /* webpackChunkName: "components/ui-trainings-training" */).then(c => wrapFunctional(c.default || c))
export const UiTrainings = () => import('../../components/ui/Trainings/index.vue' /* webpackChunkName: "components/ui-trainings" */).then(c => wrapFunctional(c.default || c))
export const UiBadgesChest = () => import('../../components/ui/Badges/Chest/Index.vue' /* webpackChunkName: "components/ui-badges-chest" */).then(c => wrapFunctional(c.default || c))
export const UiQuizQuestionImages = () => import('../../components/ui/Quiz/question/Images.vue' /* webpackChunkName: "components/ui-quiz-question-images" */).then(c => wrapFunctional(c.default || c))
export const UiQuizQuestion = () => import('../../components/ui/Quiz/question/index.vue' /* webpackChunkName: "components/ui-quiz-question" */).then(c => wrapFunctional(c.default || c))
export const UiBadgesChestFramesFive = () => import('../../components/ui/Badges/Chest/Frames/Five.vue' /* webpackChunkName: "components/ui-badges-chest-frames-five" */).then(c => wrapFunctional(c.default || c))
export const UiBadgesChestFramesFour = () => import('../../components/ui/Badges/Chest/Frames/Four.vue' /* webpackChunkName: "components/ui-badges-chest-frames-four" */).then(c => wrapFunctional(c.default || c))
export const UiBadgesChestFramesOne = () => import('../../components/ui/Badges/Chest/Frames/One.vue' /* webpackChunkName: "components/ui-badges-chest-frames-one" */).then(c => wrapFunctional(c.default || c))
export const UiBadgesChestFramesStart = () => import('../../components/ui/Badges/Chest/Frames/Start.vue' /* webpackChunkName: "components/ui-badges-chest-frames-start" */).then(c => wrapFunctional(c.default || c))
export const UiBadgesChestFramesThree = () => import('../../components/ui/Badges/Chest/Frames/Three.vue' /* webpackChunkName: "components/ui-badges-chest-frames-three" */).then(c => wrapFunctional(c.default || c))
export const UiBadgesChestFramesTwo = () => import('../../components/ui/Badges/Chest/Frames/Two.vue' /* webpackChunkName: "components/ui-badges-chest-frames-two" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
